import { createRouter, createWebHistory } from 'vue-router';
import store from './store';

// Import components
import HomepageMain          from './components/homepage/HomepageMain.vue';
import ChallengeMain         from './components/challenges/ChallengeMain.vue';
import HighscoreMain         from './components/highscores/HighscoreMain.vue';
import PatchlogMain          from './components/patchlog/PatchlogMain.vue';
import ErrorMain             from './components/error/ErrorMain.vue';
import LoginMain             from './components/authentication/LoginMain.vue';
import LogoutMain            from './components/authentication/LogoutMain.vue';
import SignupMain            from './components/authentication/SignupMain.vue';
import SignupComplete        from './components/authentication/SignupComplete.vue';
import SignupConfirm         from './components/authentication/SignupConfirm.vue';
import PasswordChangeRequest from './components/authentication/PasswordChangeRequest.vue';
import PasswordChangeForm    from './components/authentication/PasswordChangeForm.vue';
import UserMain              from './components/user/UserMain.vue';

/**
 * Redirect function for unauthenticated users
 */
async function redirectUnauthenticated(to) {
    // First try to load the user to make sure users don't have to relog after
    // doing a full refresh 
    await store.dispatch('loadUser');

    // Check if user is authenticated
    if (!store.getters.authenticated) {
        // redirect the user to the login page
        return { name: 'login', query: { next: to.path } };
    } 

    // User is logged in, return true
    return true;
}

/**
 * Add all routes
 */
const routes = [
    { path: '/',                               name: 'homepage'       , component: HomepageMain                                                  },
    { path: '/challenges/',                    name: 'challenges'     , component: ChallengeMain, props: true                                    },
    { path: '/highscores/',                    name: 'highscores'     , component: HighscoreMain                                                 },
    { path: '/patchlog/',                      name: 'patchlog'       , component: PatchlogMain                                                  },
    { path: '/accounts/login/',                name: 'login'          , component: LoginMain                                                     },
    { path: '/accounts/logout/',               name: 'logout'         , component: LogoutMain                                                    },
    { path: '/accounts/signup/',               name: 'signup'         , component: SignupMain                                                    },
    { path: '/accounts/signup_complete/',      name: 'signup_complete', component: SignupComplete                                                },
    { path: '/accounts/password_reset/' ,      name: 'password_reset' , component: PasswordChangeRequest                                         },
    { path: '/accounts/reset/:uidb64/:token/', name: 'password_change', component: PasswordChangeForm, props: true                               },
    { path: '/confirm/:token/'               , name: 'signup_confirm' , component: SignupConfirm     , props: true                               },
    { path: '/user/:userId/'                 , name: 'user'           , component: UserMain, props: true, beforeEnter: [redirectUnauthenticated] },
    { path: '/:pathMatch(.*)*'          ,      name: 'NotFound'       , component: ErrorMain                                                     },
];

const router = createRouter({
    history: createWebHistory(),
    routes : routes, 
});

export default router;