import { createStore } from 'vuex'
import { api }         from '../axios'
import { sortOptions } from './constants'

export default createStore({

    state: {
        categories             : {},
        challenges             : {},
        challengesSearch       : "",
        challengesShowCompleted: true,
        challengesSort         : sortOptions.DifficultyAscending,
        completed              : new Set(),
        containers             : {},
        containerLock          : null,
        highscore              : [],
        highscoreSearch        : "",
        highscoreShowStaff     : false,
        patches                : [],
        recentSolves           : [],
        userProfiles           : {},
        user                   : {
            rank : 'N/A',
        },
        solvers                : {
            visible: false,
            title: "",
            users: []
        },
    },

    mutations: {
        /**
         * Set challenge categories.
         */
        setCategories(state, categories) {
            state.categories = categories;
        },

        /**
         * Set a category expansion
         */
        setCategoryExpand(state, expand) {
            state.categories[expand.category].expand = expand.expand
        },

        /**
         * Set error message for specific challenge.
         */
        setChallengeMessageError(state, error) {
            state.challenges[error.id].message_error = error.message;
        },

        /**
         * Set info message for specific challenge.
         */
        setChallengeMessageInfo(state, info) {
            state.challenges[info.id].message_info = info.message;
        },

        /**
         * Set success message for specific challenge.
         */
        setChallengeMessageSuccess(state, success) {
            state.challenges[success.id].message_success = success.message;
        },

        /**
         * Set challenges to a new value.
         */
        setChallenge(state, challenge) {
            state.challenges[challenge.id] = challenge;
        },

        /**
         * Set challenges to a new value.
         */
        setChallenges(state, challenges) {
            state.challenges = challenges;
        },

        /**
         * Set challengesSearch to a new value.
         */
        setChallengesSearch(state, value) {
            state.challengesSearch = value;
        },

        /**
         * Set challengesShowCompleted to a new value.
         */
        setChallengesShowCompleted(state, value) {
            state.challengesShowCompleted = value;
        },

        /**
         * Set challengesSort to a new value.
         */
        setChallengesSort(state, value) {
            // Only set value when it is present in sort options
            if (value in sortOptions){
                state.challengesSort = sortOptions[value];
            }

            // Return success
            return value in sortOptions;
        },

        /**
         * Set completed to a new value.
         */
        setCompleted(state, completed) {
            state.completed = completed;
        },

        /**
         * Set running containers to a new value.
         */
        setContainers(state, containers) {
            state.containers = containers;
        },

        /**
         * Set container lock
         */
        setContainerLock(state, value) {
            state.containerLock = value;
        },

        /**
         * Set highscore values
         */
        setHighscore(state, value) {
            state.highscore = value;
        },

        /**
         * Set highscore search value
         */
        setHighscoreSearch(state, value) {
            state.highscoreSearch = value;
        },

        /**
         * Set highscore show staff
         */
        setHighscoreShowStaff(state, value) {
            state.highscoreShowStaff = value;
        },

        /**
         * Set patches array.
         */
         setPatches(state, patches) {
            state.patches = patches;
        },

        /**
         * Set solves to a new value.
         */
        setRecentSolves(state, solves) {
            state.recentSolves = solves;
        },

        /**
         * Set the user to a new value.
         */
        setUser(state, user) {
            state.user = user;
        },

        /**
         * Set the solves for a single user.
         */
        setUserProfile(state, {userId, profile}) {
            state.userProfiles[userId] = profile;
        },

        /**
         * Sets the displayed solvers.
         */
        setSolvers(state, solvers) {
            state.solvers = solvers;
        },
    },

    actions: {
        /**
         * Start container with a given id
         */
        async containerStart(state, id) {
            // Only start container if the lock is available
            if (state.containerLock)
                return;

            // Set container lock
            state.commit('setContainerLock', id);

            try {
                // Start container
                const response = await api.get("/api/challenges/container/" + id + '/start');

                // Check if request was successful
                if (response.data.status === 'success'){
                    // Reload running containers
                    await state.dispatch('loadContainers');
                } else {
                    // Set error for challenge
                    state.commit('setChallengeMessageError', {
                        id     : id,
                        message: response.data.message,
                    });
                }
            } catch (e) {
                // Set error for challenge
                state.commit('setChallengeMessageError', {
                    id     : id,
                    message: "A server error occurred (" + e.message + "). Please try again later or contact support.",
                });
            } finally {
                // Release container lock
                state.commit('setContainerLock', null);
            }
        },

        /**
         * Stop container with a given id
         */
        async containerStop(state, id) {
            // Only stop container if the lock is available
            if (state.containerLock)
                return;

            // Set container lock
            state.commit('setContainerLock', id);

            try {
                // Stop container
                const response = await api.get("/api/challenges/container/" + id + '/stop');
                
                // Check if request was successful
                if (response.data.status === 'success'){
                    // Reload running containers
                    await state.dispatch('loadContainers');
                } else {
                    // Set error for challenge
                    state.commit('setChallengeMessageError', {
                        id     : id,
                        message: response.data.message,
                    });
                }
            } catch (e) {
                // Set error for challenge
                state.commit('setChallengeMessageError', {
                    id     : id,
                    message: "A server error occurred (" + e.message + "). Please try again later or contact support.",
                });
            } finally {
                // Release container lock
                state.commit('setContainerLock', null);
            }
        },

        /**
         * Load single challenge from server.
         */
        async loadChallenge({commit, state}, id) {
            // Load challenges from API
            const response = await api.get('/api/challenges/info/' + id);
            var challenge = response.data;

            // Check if challenge was loaded previously
            if (challenge.id in state.challenges){
                // Set default attributes
                challenge.expanded        = state.challenges[id].expanded;
                challenge.message_error   = state.challenges[id].message_error;
                challenge.message_info    = state.challenges[id].message_info;
                challenge.message_success = state.challenges[id].message_success;
                challenge.show            = state.challenges[id].show;
                challenge.show_hints      = state.challenges[id].show_hints;

            } else {
                // Set default attributes
                challenge.expanded        = false;
                challenge.message_error   = null;
                challenge.message_info    = null;
                challenge.message_success = null;
                challenge.show            = true;
                if (challenge.hints){
                    challenge.show_hints = new Array(challenge.hints).fill(false);
                } else {
                    challenge.show_hints = new Array(0);
                }
            }
            // TODO rewrite category to include ID on frontend
            challenge.category = challenge.category.name;

            // Set challenges
            commit('setChallenge', challenge);
        },

        /**
         * Load all challenges from server.
         */
        async loadChallenges(state) {
            // Initialise challenges
            var challenges = {};

            // Load challenges from API
            const response = await api.get('/api/challenges/info/');

            // Loop over challenges
            for (var challenge of response.data){
                // Set default attributes
                challenge.expanded        = false;
                challenge.message_error   = null;
                challenge.message_info    = null;
                challenge.message_success = null;
                challenge.show            = true;
                if (challenge.hints){
                    challenge.show_hints = new Array(challenge.hints).fill(false);
                } else {
                    challenge.show_hints = new Array(0);
                }
                // TODO rewrite category to include ID on frontend
                challenge.category = challenge.category.name;

                // Add challenge to challenges
                challenges[challenge.id] = challenge;
            }

            // Set challenges
            state.commit('setChallenges', challenges);

            // Compute categories
            var categories = {};

            // Loop over all categories
            for (var category of Object.values(challenges).map(c => c.category)){
                let icon_path = null;

                try {
                    icon_path = require(`../assets/icon_${category.toLowerCase()}.png`);
                } catch {
                    icon_path = require(`../assets/g8793.png`);
                }

                // Add to categories
                categories[category] = {
                    expand: category == 'intro',
                    icon_path: icon_path, // Add path to category icon
                }
            }

            // Set categories
            state.commit('setCategories', categories);
        },

        /**
         * Load completed challenge IDs from server.
         */
        async loadCompleted(state) {
            // Load completed challenge IDs from API
            const response = await api.get('/api/challenges/score/completed');
            var completed = new Set(response.data.completed);

            // Set completed
            state.commit('setCompleted', completed);
        },

        /**
         * Load running containers from server.
         */
        async loadContainers(state) {
            // Load running containers from API
            const response = await api.get('/api/challenges/container/running');
            var containers = response.data.containers;

            // Set containers
            state.commit('setContainers', containers);
        },

        /**
         * Load all highscores
         */
        async loadHighscore(state) {
            // Load completed challenge IDs from API
            var response = await api.get('/api/challenges/score/highscore');
            // Set highscores
            state.commit('setHighscore', response.data.highscores);
        },

         /**
         * Load all patches from server.
         */
          async loadPatches(state) {
            // Initialise patches list
            let patches = [];

            // Load challenges from API
            const response = await api.get('/api/homepage/patchlog');

            // Loop over challenges
            for (const patch of response.data.patches){
                patches.push(patch)
            }

            // Set challenges
            state.commit('setPatches', patches);
        },

        /**
         * Load <amount> most recent solves from server
         */
        async loadRecentSolves(state, amount) {
            // Load recent solves from API
            const response = await api.get('/api/challenges/score/recent?limit=' + amount);
            var solves = response.data.solved;

            // Set recent solves
            state.commit('setRecentSolves', solves);
        },

        /**
         * Load user stats from server
         */
        async loadUser(state) {
            // If user is already loaded, skip
            if (state.getters.authenticated) {
                return;
            }

            // Load user stats from API
            const response = await api.get('/api/challenges/score/user');
            var user = response.data;

            // Set user
            state.commit('setUser', user);
        },

        /**
         * Set visibility of challenges based on
         */
        searchChallenges({commit, state}, filter) {
            // Set filter to toLowerCase
            filter = filter.toLowerCase()

            // Set challengesSearch value
            commit('setChallengesSearch', filter);

            // Clone challenges
            var challenges = Object.assign({}, state.challenges);
            var showing = new Array(0);

            // Loop over all challenges
            for (var challenge of Object.values(challenges)){
                // Check if we should show challenge
                var show = (
                    challenge.description       .toLowerCase().includes(filter)|
                    challenge.title             .toLowerCase().includes(filter)|
                    challenge.authors.join(', ').toLowerCase().includes(filter)|
                    challenge.category          .toLowerCase().includes(filter)
                );

                // Set new show value of challenge
                challenge.show     = show;
                challenge.expanded = false;

                // Set showing challenge IDs
                if (challenge.show)
                    showing.push(challenge.id);
            }

            // In case of a single showing challenge, expand challenge
            if (showing.length === 1){
                challenges[showing[0]].expanded = true;
            }

            // Set updated challenges
            commit('setChallenges', challenges);

            // Loop over all categories
            for (var category of Object.keys(state.categories)){
                // Expand categories if search is not empty
                var expand = {
                   category: category,
                   expand  : filter !== "",
                };

                // Set category expand value
                commit('setCategoryExpand', expand);
            }
        },

        /**
         * Set visibility of challenges based on
         */
        searchHighscores(state, filter) {
            // Set filter to toLowerCase
            filter = filter.toLowerCase()

            // Set challengesSearch value
            state.commit('setHighscoreSearch', filter);
        },

        /**
         * Direct user to a given challenge based on a given challenge id.
         */
        selectChallenge({commit, state}, id){            
            // Clone challenges
            var challenges = Object.assign({}, state.challenges);
            var challengeCategory = null;

            // Loop over all challenges
            for (var challenge of Object.values(challenges)){
                // Unexpand all challenges
                challenge.expanded = false;

                // Set challenge category
                if (challenge.id == id){
                    // Expand challenge if equal id
                    challenge.expanded = true;
                    challengeCategory = challenge.category;
                }
            }

            // Set updated challenges
            commit('setChallenges', challenges);

            // Loop over all categories
            for (var category of Object.keys(state.categories)){
                // Expand categories if search is not empty
                var expand = {
                   category: category,
                   expand  : challengeCategory == category,
                };

                // Set category expand value
                commit('setCategoryExpand', expand);
            }

            // Show element to user
            setTimeout(function(){
                // Get challenge element
                var element = document.getElementById(id);
                // Scroll to element
                if (element) {
                    element.scrollIntoView();
                }
            }, 50);
        },

        /**
         * Loads and presents the solvers of the provided challenge.
         */
        async showSolvers(state, challengeId) {
            const response = await api.get(`/api/challenges/solvers/${challengeId}`);
            
            let solvers = {
                visible: true,
                title: response.data["title"],
                users: response.data["solvers"]
            };

            console.log(solvers);

            state.commit('setSolvers', solvers);
        },

        /**
         * Load user stats from server
         */
        async submitFlag(state, flag) {
            // Submit flag
            const response = await api.post('/api/challenges/submit/flag', {
                'identifier': flag.id,
                'flag'      : flag.flag,
            });

            // Add warning messages, if any
            if (response.data.warning && response.data.warning.length > 0){
                state.commit('setChallengeMessageInfo', {
                    id     : flag.id,
                    message: response.data.warning.join('; '),
                });
            }

            // Add success messages, if any
            if (response.data.success && response.data.success.length > 0){
                // Reload data
                state.dispatch('loadChallenge', flag.id);
                state.dispatch('loadCompleted');
                state.dispatch('loadRecentSolves', 4);
                state.dispatch('loadUser');

                // Set success message
                state.commit('setChallengeMessageSuccess', {
                    id     : flag.id,
                    message: response.data.success.join('; '),
                });
            }
        },

        /**
         * Toggle a category expansion
         */
         toggleCategoryExpand({commit, state}, category) {
             // Set new expand value
             var expand = {
                category: category,
                expand  : !state.categories[category].expand,
             };

             // Set category expand value
             commit('setCategoryExpand', expand);
         },

        /**
         * Remove user object from store
         */
         unloadUser(state) {
            // Replace user in state with default user
            state.commit('setUser', {
                rank : 'N/A',
            });
        },

        /**
         * Ensures the solves for the provided user are loaded.
         */
        async loadUserProfile(state, userId) {
            const response = await api.get('/api/challenges/profile/' + userId);
            const profile = response.data;
            this.commit('setUserProfile', {userId, profile});
        }
    },

    methods: {
        /**
         * Get cookies as an object.
         */
        getCookies(){
            // Initialise result
            var result = {};

            // Get cookies
            var cookies = decodeURIComponent(document.cookie).split(';');

            // Loop over cookies
            for (var cookie of cookies){
                // Get cookie as pair
                cookie = cookie.trim().split('=');
                // Set result
                result[cookie[0]] = cookie[1];
            }

            // Return result
            return result;
        },
    },

    getters: {
        /**
         * WARNING, THIS METHOD DOES NOT GIVE ANY GUARANTEES WHETHER THE USER IS ACTUALLY AUTHENTICATED.
         * SENSITIVE INFORMATION SHOULD NOT BE GUARDED BY A CHECK WITH THIS METHOD, IT ONLY EXISTS FOR 
         * UX PURPOSES, NOT SECURITY PURPOSES.
         * 
         * Compute whether the user is authenticated based on the state.user object
         */
        authenticated(state) {
            // If the user object is properly set, it has an id property. 
            return Object.prototype.hasOwnProperty.call(state.user, 'id');
        }

    },
})
