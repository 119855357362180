<template>
    <div class="container-fluid">
        <!-- About the THS -->
        <section id="about">
            <h1>Twente Hacking School</h1>
            <div class="row">
                <div class="col-md-12">
                    <p>
                        Welcome to the Twente Hacking School, the education platform from the University of Twente that teaches you about practical aspects of security.
                        This platform hosts assignments from multiple courses in the Cyber Security master programme.
                        To view the course assignments, make sure you have <router-link :to="{ name: 'signup' }">registered an account</router-link> and are <router-link :to="{ name: 'login' }">logged in</router-link>, then navigate to the <router-link :to="{ name: 'challenges' }">Challenges</router-link> page.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <img src="../../assets/logo_ut.png" alt="THS logo">
                </div>
                <div class="col-md-6">
                    <img style="width:75%" src="../../assets/g8793.png" alt="THS logo">
                </div>
            </div>
        </section>
    </div>
</template>

<script>

// Export app
export default {
  name: 'HomepageMain',

  // Change height to auto
  mounted(){
      document.body.style.height = "auto";
  },
}
</script>


<style lang="css" scoped>
    .row {
        margin-bottom: 50px;
    }

    img {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: auto;
    }
    .home-page-button {
        background-color: #800080;
        margin-bottom: 10px;
        color: white;
        text-align: center;
        width: 100%;
        font-size: 15px;
    }
</style>