<template>

  <div class="container-fluid">
      <!-- Header -->
      <div class="row justify-content-center">
          <div class="col-lg-12">
              <h1>Challenges</h1>

              <!-- Recent activity and scoring -->
              <div v-if="this.$store.getters.authenticated" class="row margin-top-medium">

                  <!-- Recent challenges -->
                  <div class="col-lg-5 border-right">
                      <ChallengeRecent></ChallengeRecent>
                  </div>

                  <!-- Score/Rank -->
                  <div class="col-lg-2">
                      <PlayerScore></PlayerScore>
                  </div>

                  <!-- Recent solves -->
                  <div class="col-lg-5 border-left">
                      <ChallengeSolves></ChallengeSolves>
                  </div>
              </div>

              <!-- Show message limited challenges when not logged in -->
              <div v-else class="message-unauthenticated">
                Welcome to the THS challenges!
                Below, you find some introduction challenges that you can play without an account to see if playing capture the flags is something you like.
                Give them a try, and if you like the challenges and want to do more,
                <router-link :to="{ name: 'signup' }">create an account</router-link> or
                <router-link :to="{ name: 'login' }">login</router-link> to see all of our challenges.
                And join us at the THS!
              </div>
          </div>
      </div>

      <!-- Challenges -->
      <div class="row justify-content-center">
          <!-- Left sidebar -->
          <div v-if="this.$store.getters.authenticated" id="challengeFilter" class="col-lg-2 sidebarStatic sidebar">
              <ChallengeFilter></ChallengeFilter>
          </div>

          <!-- Main content -->
          <div class="col-lg-12 px-0">

              <!-- Success/warning messages -->
              <div class="row">
                  <MessagesWarn></MessagesWarn>
                  <MessagesSuccess></MessagesSuccess>
              </div>

              <!-- Challenges -->
              <div class="row">
                  <ChallengeOverview></ChallengeOverview>
              </div>

          </div>
      </div>

      <!-- Images source -->
      <div class="row">
          <div class="col-md-12 footer-color">
              Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
          </div>
      </div>

      <!-- Modal -->
      <ChallengeSolvesDialog :visible="solvesDialogVisible" />
  </div>
</template>

<script>

// Import methods
import { mapActions } from 'vuex'

// Import components
import ChallengeFilter       from './ChallengeFilter.vue'
import ChallengeOverview     from './ChallengeOverview.vue'
import ChallengeRecent       from './ChallengeRecent.vue'
import ChallengeSolves       from './ChallengeSolves.vue'
import ChallengeSolvesDialog from './ChallengeSolvesDialog.vue'
import MessagesWarn          from './MessagesWarn.vue'
import MessagesSuccess       from './MessagesSuccess.vue'
import PlayerScore           from './PlayerScore.vue'

// Export app
export default {
  name: 'ChallengeMain',

  // Set components
  components: {
      ChallengeFilter,
      ChallengeOverview,
      ChallengeRecent,
      ChallengeSolves,
      ChallengeSolvesDialog,
      MessagesWarn,
      MessagesSuccess,
      PlayerScore,
  },

  methods: {
      /**
       * Get scroll position of current window.
       */
      onScroll(){
          // Get challenge filter element
          let filter = document.getElementById('challengeFilter');

          if (window.pageYOffset >= 173){
              filter.classList.remove('sidebarStatic');
              filter.classList.add   ('sidebarDynamic');
          } else {
              filter.classList.remove('sidebarDynamic');
              filter.classList.add   ('sidebarStatic');
          }
      },

      /**
       * Map actions from central store
       */
      ...mapActions([
          'loadChallenges',
          'loadCompleted',
          'loadContainers',
          'selectChallenge',
      ]),
  },

  computed: {
    solvesDialogVisible() {
        return this.$store.state.solvers.visible;
    }
  },

  beforeMount() {
      this.loadChallenges().then(() => {
        if (this.$route.query.id !== undefined) {
            this.selectChallenge(this.$route.query.id);
        }
      });
      this.loadCompleted();
      this.loadContainers();
  },

  // Change height to auto
  mounted(){
      document.body.style.height = "auto";

      // Add event listener to scroll
      window.addEventListener("scroll", this.onScroll);
  },
}
</script>

<style>
    .footer-color {
        color: #888;
    }

    .margin-top-medium {
        margin-top: 50px;
    }

    .message-unauthenticated {
        font-size: larger;
        margin-top: 20px;
    }

    .pad-medium {
        margin-top: 50px;
    }

    .margin-top {
        margin-top: 20px;
    }

    @media only screen
    and (min-width : 992px) {
        .container {
            max-width: none;
            padding-left: 16vw;
            padding-right: 16vw;
        }

        .sidebarStatic {
            position: absolute;
            top: 223px;
            left: 0;
        }

        .sidebarDynamic {
            position: fixed; /* Make the sidebar immovable*/
            z-index: 1; /* Side bar stays at the top */
            top: 50px;
            left: 0;
            overflow-x: hidden; /* restricting horizontal scroll */
        }

        .border-right {
            border-right: 1px solid rgb(222, 226, 230)
        }

        .border-left {
            border-left: 1px solid rgb(222, 226, 230)
        }
    }

    @media only screen
    and (max-width : 992px) {
        .sidebar {
            margin-top: 20px;
        }
    }

</style>
